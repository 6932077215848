import React, { Suspense } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import Layout from "layouts";
import { AxiosInterceptor } from 'helpers/api';

const AuthLayout = React.lazy(() => import(/* webpackChunkName: "views-authLayout" */ "views/authLayout"));
const Homepage = React.lazy(() => import(/* webpackChunkName: "views-home" */ "views/home"));
const ConfirmPage = React.lazy(() => import(/* webpackChunkName: "views-confirm" */ "views/confirm"));
const HistoryPage = React.lazy(() => import(/* webpackChunkName: "views-history" */ "views/history"));
const RegistPage = React.lazy(() => import(/* webpackChunkName: "views-regist" */ "views/regist"));
const LoginPage = React.lazy(() => import(/* webpackChunkName: "views-login" */ "views/login"));

const VerifyAccountPage = React.lazy(() => import(/* webpackChunkName: "views-verify-account" */ "views/verifyAccount"));
const ActivationSentPage = React.lazy(() => import(/* webpackChunkName: "views-activation-sent" */ "views/activationSent"));
const ActivatePasswordPage = React.lazy(() => import(/* webpackChunkName: "views-reset-password" */ "views/activatePassword"));

const ForgotPasswordPage = React.lazy(() => import(/* webpackChunkName: "views-forgot-password" */ "views/forgotPassword"));
const ForgotEmailSentPage = React.lazy(() => import(/* webpackChunkName: "views-forgot-email-sent" */ "views/forgotEmailSent"));
const ResetPasswordPage = React.lazy(() => import(/* webpackChunkName: "views-forgot-email-sent" */ "views/resetPassword"));

const ForbiddenPage = React.lazy(() => import(/* webpackChunkName: "views-403" */ "views/403"));
const NotFoundPage = React.lazy(() => import(/* webpackChunkName: "views-404" */ "views/404"));

const Router = () => {

  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <AxiosInterceptor>
          <Layout>
            <Routes>
              {/* <Route index element={<Navigate to="/home" />} /> */}

              <Route exact path="/regist" element={<RegistPage />} />
              <Route exact path="/login" element={<LoginPage />} />
              <Route exact path="/account-activation" element={<VerifyAccountPage />} />
              <Route exact path="/activation-sent" element={<ActivationSentPage />} />
              <Route exact path="/activate-password/:token" element={<ActivatePasswordPage />} />

              <Route exact path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route exact path="/forgot-sent" element={<ForgotEmailSentPage />} />
              <Route exact path="/reset-password/:token" element={<ResetPasswordPage />} />

              <Route exact path="/403" element={<ForbiddenPage />} />

              <Route path="/" element={<AuthLayout />}>
                <Route index element={<Homepage />} />

                <Route exact path="home" element={<Homepage />} />
                <Route exact path="confirm" element={<ConfirmPage />} />
                <Route exact path="history" element={<HistoryPage />} />
              </Route>

              <Route path="*" element={<NotFoundPage />} />

            </Routes>
          </Layout>
        </AxiosInterceptor>
      </BrowserRouter>
    </Suspense>
  );
};

export default Router;
